import React from 'react';
import Main from '../containers/Layout';
import inventoryStockManagementContent from '../../content/pages/inventory-and-stock-management.yml';
import InventoryAndStockManagementContainer from '@/containers/toolkit/InventoryAndStockManagementContainer';

export interface InventoryStockManagementPageContent {
  viewport1: {
    title1: string;
    title2: string;
    title3: string;
    description: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    title: string;
    carouselItems: {
      title: string;
      description: string;
    }[];
  };
  viewport3: {
    cards: {
      title: string;
      redText?: string;
      description1: string;
      description2?: string;
      image: string;
    }[];
  };
  viewport4: {
    title: string;
    leftColumn: { title: string; description: string }[];
    rightColumn: { title: string; description: string }[];
    bottomText: string;
    button: { label: string; url: string };
  };
}

const InventoryAndStockManagement: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4 } =
    inventoryStockManagementContent as unknown as InventoryStockManagementPageContent;
  return (
    <Main>
      <InventoryAndStockManagementContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
        }}
      />
    </Main>
  );
};

export default InventoryAndStockManagement;
